/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Slider from 'react-slick';
import H2 from '../../../atoms/Label/Headers/H2';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import ImageRender from '../../../../helper/image';
import { Wrapper } from './style';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	arrows: false,
};

const Trusted = () => {
	const intl = useIntl();

	const listClient1 = [
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%205.png?format=webp&q=100',
			className: 'maximum',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%204.png?format=webp&q=100',
			className: 'maximumSmall',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%203.png?format=webp&q=100',
			className: 'maximumSmall',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2010.png?format=webp&q=100',
			className: 'maximumSmall',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2020.png?format=webp&q=100',
			className: 'maximumSmall',
		},

		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2015.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2013.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2012.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2022.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%206.png?format=webp&q=100',
			className: '',
		},

		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2016.png?format=webp&q=100',
			className: '',
		},
	];

	const listClient2 = [
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%201.png?format=webp&q=100',
			className: 'maximum',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2011.png?format=webp&q=100',
			className: 'maximumSmall',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%209.png?format=webp&q=100',
			className: 'maximumSmall',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2014.png?format=webp&q=100',
			className: 'maximumSmall',
		},

		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%202.png?format=webp&q=100',
			className: 'maximumSmall',
		},

		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%208.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%207.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2021.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2018.png?format=webp&q=100',
			className: '',
		},
		{
			image:
				'https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2019.png?format=webp&q=100',
			className: '',
		},
	];
	return (
		<Wrapper>
			<div className="container">
				<div className="textWrapper">
					<H2>{intl.formatMessage({ id: `homepage.trusted.title` })}</H2>
					<Paragraph>{intl.formatMessage({ id: `homepage.trusted.sub` })}</Paragraph>
				</div>

				<div className="slick-trusted">
					<Slider {...settings}>
						<div>
							<div className="imageListWrapper">
								{listClient1.map(v => (
									<div className="list">{ImageRender(v.image, v.image, v.image, v.className)}</div>
								))}
							</div>
						</div>
						<div>
							<div className="imageListWrapper">
								{listClient2.map(v => (
									<div className="list">{ImageRender(v.image, v.image, v.image, v.className)}</div>
								))}
							</div>
						</div>
					</Slider>
				</div>
			</div>
		</Wrapper>
	);
};

export default Trusted;
