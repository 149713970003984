import React from 'react';
import PropTypes from 'prop-types';
import H1 from '../../../atoms/Label/Headers/H1/clear';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import ParagraphSmall from '../../../atoms/Label/Paragraph/Small';
import ButtonPrimary from '../../../atoms/Button/Primary';
import { SVGRender } from '../../../../helper/image';
import { Wrapper } from './style';

const Consultation = ({ title, desc, txtButton, onClick, idBtn }) => {
	return (
		<Wrapper>
			<div className="topBackground" />
			<div className="container container-mobile">
				<div className="wrapper-list">
					<div className="content">
						<div className="content__left">
							{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/mascot-savira-1.svg')}
						</div>
						<div className="content__right">
							<H1>{title}</H1>
							<Paragraph className="pDefault txtContent">
								<div dangerouslySetInnerHTML={{ __html: desc }} />
							</Paragraph>
							<ButtonPrimary onClick={onClick} id={idBtn}>
								<ParagraphSmall>{txtButton}</ParagraphSmall>
							</ButtonPrimary>
						</div>
						<div className="quarterCircle" />
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

Consultation.propTypes = {
	title: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	txtButton: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	idBtn: PropTypes.string.isRequired,
};

export default Consultation;
