/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Slider from 'react-slick';
import ImageRender, { SVGRender } from '../../../../helper/image';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import ParagraphSmall from '../../../atoms/Label/Paragraph/Small';
import { Wrapper } from './style';

const settings = {
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 2,
	slidesToScroll: 1,

	autoplay: false,
	arrows: false,

	responsive: [
		{
			breakpoint: 1366,
			settings: {
				slidesToShow: 2.1,
				centerMode: true,
				centerPadding: '2%',
			},
		},
		{
			breakpoint: 1100,
			settings: {
				slidesToShow: 1.1,
				centerMode: true,
			},
		},
		{
			breakpoint: 550,
			settings: {
				slidesToShow: 1.05,
				centerMode: true,
				centerPadding: '3%',
			},
		},
		{
			breakpoint: 460,
			settings: {
				slidesToShow: 1,
				centerMode: false,
			},
		},
	],
};

const Testimony = () => {
	const intl = useIntl();
	const [selectedTab, setSelectedTab] = useState(0);
	const customeSlider = useRef();
	const gotoNext = () => {
		customeSlider.current.slickNext();
	};

	const gotoPrev = () => {
		customeSlider.current.slickPrev();
	};

	const goTo = index => {
		customeSlider.current.slickGoTo(index);
	};

	const lists = [
		{
			logo: 'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-issa.png?format=webp&q=100',
			testimony: intl.formatMessage({ id: `homepage.testimony.issa.desc` }),
			name: intl.formatMessage({ id: `homepage.testimony.issa.name` }),
			image_person:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-Nurhayati-ISSA.png?format=webp&q=100',
			title: intl.formatMessage({ id: `homepage.testimony.issa.title` }),
			type: intl.formatMessage({ id: `homepage.testimony.issa.type` }),
		},
		{
			logo:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-sharinghappiness.png?format=webp&q=100',
			testimony: intl.formatMessage({ id: `homepage.testimony.sharing_happines.desc` }),
			name: intl.formatMessage({ id: `homepage.testimony.sharing_happines.name` }),
			image_person:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-Zaeni-Ramdan-SharingHappines.png?format=webp&q=100',
			title: intl.formatMessage({ id: `homepage.testimony.sharing_happines.title` }),
			type: intl.formatMessage({ id: `homepage.testimony.sharing_happines.type` }),
		},
		{
			logo: 'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-edenfarm.png?format=webp&q=100',
			testimony: intl.formatMessage({ id: `homepage.testimony.edenfarm_2.desc` }),
			image_person:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-Darren-C-Edenfarm.png?format=webp&q=100',
			name: intl.formatMessage({ id: `homepage.testimony.edenfarm_2.name` }),
			title: intl.formatMessage({ id: `homepage.testimony.edenfarm_2.title` }),
			type: intl.formatMessage({ id: `homepage.testimony.edenfarm_2.type` }),
		},
		{
			logo: 'https://seiturju.sirv.com/Chatbiz/v4/client/bajuyulidanborongdong.png?format=webp&q=100',
			testimony: intl.formatMessage({ id: `homepage.testimony.bajuyuli.desc` }),
			image_person:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-Ghani-Rozaqi-Bajuyuli.png?format=webp&q=100',
			name: intl.formatMessage({ id: `homepage.testimony.bajuyuli.name` }),
			title: intl.formatMessage({ id: `homepage.testimony.bajuyuli.title` }),
			type: intl.formatMessage({ id: `homepage.testimony.bajuyuli.type` }),
		},
		{
			logo: 'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-manulife.png?format=webp&q=100',
			testimony: intl.formatMessage({ id: `homepage.testimony.manulife.desc` }),
			image_person:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-Yulia-Anggiarini-Manulife.png?format=webp&q=100',
			name: intl.formatMessage({ id: `homepage.testimony.manulife.name` }),
			title: intl.formatMessage({ id: `homepage.testimony.manulife.title` }),
			type: intl.formatMessage({ id: `homepage.testimony.manulife.type` }),
		},
		{
			logo: 'https://seiturju.sirv.com/Chatbiz/v4/client/chatatid.png?format=webp&q=100',
			testimony: intl.formatMessage({ id: `homepage.testimony.chatat.desc` }),
			image_person:
				'https://seiturju.sirv.com/Chatbiz/v2/testimony/testimony-Grandy-Prajayakti.png?format=webp&q=100',
			name: intl.formatMessage({ id: `homepage.testimony.chatat.name` }),
			title: intl.formatMessage({ id: `homepage.testimony.chatat.title` }),
			type: intl.formatMessage({ id: `homepage.testimony.chatat.type` }),
		},
	];

	return (
		<Wrapper>
			<div className="container container-mobile">
				<Slider
					{...settings}
					beforeChange={(current, next) => {
						setSelectedTab(next);
					}}
					ref={customeSlider}
				>
					{lists.map(v => {
						return (
							<div>
								<div className="list-wrapper">
									<div className="logo">
										{ImageRender(v.logo, v.logo, v.logo, 'client-logo')}
										<div className="type">
											<ParagraphSmall>{v.type}</ParagraphSmall>
										</div>
									</div>
									<Paragraph className="pDefault textimony-desc">{v.testimony}</Paragraph>
									<div className="by-wrapper">
										<div className="by-wrapper__left">
											<div className="image-wrapper">
												{ImageRender(v.image_person, v.image_person, v.image_person, 'by-img')}
											</div>
										</div>
										<div className="by-wrapper__right">
											<Paragraph className="pDefault name">{v.name}</Paragraph>
											<Paragraph className="pDefault job-title">{v.title}</Paragraph>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>

			<div className="container container-mobile">
				<div className="bottomTestimony">
					<div className="listDots">
						<ul>
							{lists.map((v, index) => {
								return (
									<li
										onClick={() => {
											goTo(index);
										}}
										onKeyDown={() => {
											goTo(index);
										}}
										className={selectedTab === index ? 'active' : ''}
									/>
								);
							})}
						</ul>
					</div>
					<div className="btnWrapper">
						<button type="button" onClick={() => gotoPrev()}>
							{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-arrow-orange-border.svg')}
						</button>
						<button type="button" onClick={() => gotoNext()}>
							{SVGRender('https://seiturju.sirv.com/Chatbiz/v4/ico-arrow-orange-border.svg')}
						</button>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default Testimony;
