import styled from 'styled-components';
import { Tablet, Large, Mobile } from '../../../atoms/_Variable/var';

export const CUSTOM_TABLET = 990;

export const Wrapper = styled.section`
	background-color: #f9fbff;
	padding-top: 3.438rem;

	.textWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-left: 20%;
		padding-right: 20%;
		@media screen and (max-width: 1035px) {
			padding-left: 5%;
			padding-right: 5%;
		}
		@media screen and (max-width: ${Tablet}) {
			padding-left: 0%;
			padding-right: 0%;
		}

		@media screen and (min-width: ${Large}) {
			padding-left: 0%;
			padding-right: 0%;
		}
		.pDefault {
			max-width: 760px;
			margin-top: 1.5rem;
			@media screen and (min-width: ${Large}) {
				max-width: 960px;
			}
		}
	}

	.slick-dots {
		bottom: -75px;
		li {
			&:not(:last-child) {
				margin-right: 1rem;
			}
			button {
				&::before {
					width: 14px;
					height: 14px;
					content: '';
					background: #bbdde6 !important;
					opacity: 1;
					border-radius: 50%;
				}
			}

			&.slick-active {
				button {
					&::before {
						width: 14px;
						height: 14px;
						content: '';
						background: #ff8f1c !important;
						opacity: 1;
					}
				}
			}
		}
	}
	.slick-trusted {
		margin-top: 1.375rem;
		padding-left: 15%;
		padding-right: 15%;
		@media screen and (max-width: ${Tablet}) {
			padding-left: 0;
			padding-right: 0;
		}
		.imageListWrapper {
			display: flex;
			align-items: center;
			justify-content: center;

			flex-wrap: wrap;

			.list {
				width: 25%;
				margin-top: 2.438rem;
				@media screen and (max-width: ${Mobile}) {
					width: 33.333%;
					margin-top: 1.563rem;
				}
				picture,
				img {
					max-width: 90%;
					max-height: 50px;
					filter: grayscale(100%);
					display: flex;
					align-items: center;
					justify-content: center;
					-webkit-backface-visibility: hidden;
					-ms-transform: translateZ(0);
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					image-rendering: -moz-crisp-edges;
					image-rendering: -o-crisp-edges;
					image-rendering: -webkit-optimize-contrast;
					image-rendering: crisp-edges;
					-ms-interpolation-mode: nearest-neighbor;
					&:hover {
						filter: grayscale(0%);
					}
				}
			}
		}
	}
`;

export default Wrapper;
