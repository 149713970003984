/* eslint-disable import/no-unresolved */
import React from 'react';
import { navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import 'lazysizes';
import 'typeface-montserrat';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fontsource/nunito';

import Menu from '../../../organisms/v2/Menu';
import BannerHome from '../../../organisms/V4/BannerHome';
import GetFrom from '../../../organisms/V4/GetFrom';
import ChatbizForLeft from '../../../organisms/V4/Card/ChatbizForLeft';
import ChatbizForRight from '../../../organisms/V4/Card/ChatbizForRight';
import Trusted from '../../../organisms/V4/Trusted';
import Testimony from '../../../organisms/V4/Testimony';
import Consultation from '../../../organisms/V4/Consultation';
import Footer from '../../../organisms/Footer';
import withDefautlHoc from '../../../_hoc/global';

import withIntl from '../../../../helper/hoc/withIntl';

const HomePage = ({ enPrefix }) => {
	const intl = useIntl();

	const navigateToFormSignUp = () => {
		let currentUrl = '';
		if (typeof window !== 'undefined') {
			currentUrl = window.location.href;
		}
		if (enPrefix) {
			navigate(`/en/form/signup?fromUrl=${currentUrl}`);
		} else {
			navigate(`/form/signup?lang=id&fromUrl=${currentUrl}`);
		}
	};

	return (
		<>
			<Menu enPrefix={enPrefix} onDemoClick={navigateToFormSignUp} />
			<BannerHome enPrefix={enPrefix} onClickButton={navigateToFormSignUp} />
			<GetFrom />
			<ChatbizForLeft
				img="https://seiturju.sirv.com/Chatbiz/v4/actual/img-whatsapp-jual%20beli.png?format=webp&q=100"
				companyLogo="https://seiturju.sirv.com/Chatbiz/v4/actual/client/kino.png?format=webp&q=100"
				txtTop={intl.formatMessage({ id: `homepage.chatbiz_for_retail.header` })}
				txtTitle={intl.formatMessage({ id: `homepage.chatbiz_for_retail.title` })}
				txtContent={intl.formatMessage({ id: `homepage.chatbiz_for_retail.desc` })}
				txtButton={intl.formatMessage({ id: `homepage.chatbiz_for_retail.button` })}
				onDemoClick="https://wa.me/6289520004505?text=demo%20retail"
				idBtn="CTA-hp-retail"
			/>
			<ChatbizForRight
				img="https://seiturju.sirv.com/Chatbiz/v4/actual/img-whatsapp-operation.png?format=webp&q=100"
				companyLogo="https://seiturju.sirv.com/Chatbiz/v4/actual/client/solusiindo.png?format=webp&q=100"
				txtTop={intl.formatMessage({ id: `homepage.chatbiz_for_operation.header` })}
				txtTitle={intl.formatMessage({ id: `homepage.chatbiz_for_operation.title` })}
				txtContent={intl.formatMessage({ id: `homepage.chatbiz_for_operation.desc` })}
				txtButton={intl.formatMessage({ id: `homepage.chatbiz_for_operation.button` })}
				onDemoClick="https://wa.me/6289520004505?text=demo%20leads"
				idBtn="CTA-hp-operations"
			/>
			<ChatbizForLeft
				img="https://seiturju.sirv.com/Chatbiz/v4/actual/img-whatsapp-HR.png?format=webp&q=100"
				companyLogo="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%205.png?format=webp&q=100"
				txtTop={intl.formatMessage({ id: `homepage.chatbiz_for_hr.header` })}
				txtTitle={intl.formatMessage({ id: `homepage.chatbiz_for_hr.title` })}
				txtContent={intl.formatMessage({ id: `homepage.chatbiz_for_hr.desc` })}
				txtButton={intl.formatMessage({ id: `homepage.chatbiz_for_hr.button` })}
				onDemoClick="https://wa.me/6289520004505?text=demo%20knowledge"
				idBtn="CTA-hp-hr"
			/>
			<ChatbizForRight
				img="https://seiturju.sirv.com/Chatbiz/v4/actual/img-whatsapp-booking.png?format=webp&q=100"
				companyLogo="https://seiturju.sirv.com/Chatbiz/v4/actual/client/baraya.png?format=webp&q=100"
				txtTop={intl.formatMessage({ id: `homepage.chatbiz_for_transport.header` })}
				txtTitle={intl.formatMessage({ id: `homepage.chatbiz_for_transport.title` })}
				txtContent={intl.formatMessage({ id: `homepage.chatbiz_for_transport.desc` })}
				txtButton={intl.formatMessage({ id: `homepage.chatbiz_for_transport.button` })}
				onDemoClick="https://wa.me/6289520004505?text=demo%20travel"
				idBtn="CTA-hp-transport"
			/>
			<ChatbizForLeft
				img="https://seiturju.sirv.com/Chatbiz/v4/actual/img-whatsapp-kemitraan.png?format=webp&q=100"
				companyLogo="https://seiturju.sirv.com/Chatbiz/v4/actual/client/Client%20Logo%2013.png?format=webp&q=100"
				txtTop={intl.formatMessage({ id: `homepage.chatbiz_for_partnerships.header` })}
				txtTitle={intl.formatMessage({ id: `homepage.chatbiz_for_partnerships.title` })}
				txtContent={intl.formatMessage({ id: `homepage.chatbiz_for_partnerships.desc` })}
				txtButton={intl.formatMessage({ id: `homepage.chatbiz_for_partnerships.button` })}
				onDemoClick="https://wa.me/6289520004505?text=demo%20kemitraan"
				idBtn="CTA-hp-partnership"
			/>
			<Trusted />
			<Testimony />
			<Consultation
				title={intl.formatMessage({ id: `homepage.cta_bottom.title` })}
				desc={intl.formatMessage({ id: `homepage.cta_bottom.sub` })}
				txtButton={intl.formatMessage({ id: `homepage.cta_bottom.button` })}
				onClick={navigateToFormSignUp}
				idBtn="CTA-hp-bottom"
			/>
			<Footer enPrefix={enPrefix} />
		</>
	);
};

HomePage.propTypes = {
	enPrefix: PropTypes.string.isRequired,
};

export default withIntl(withDefautlHoc(HomePage));
