import styled from 'styled-components';
import { AverageSize, Tablet, ExtraLarge, Large, Mobile } from '../../../atoms/_Variable/var';

export const CUSTOM_TABLET = 950;
// const CUSTOM_MAX_TYPE = CUSTOM_TABLET - 1;

export const Wrapper = styled.section`
	margin-top: 40px;
	padding-top: 9rem;

	background-color: #ffffff;

	@media only screen and (max-width: ${AverageSize}) {
		padding-top: 7.5rem;
	}

	@media screen and (max-width: ${Tablet}) {
		.container-mobile {
			padding-left: 15px;
			padding-right: 15px;
			margin-left: 0;
			max-width: 100%;
		}
	}

	.hrVertical {
		border-left: 2px solid #000;
		height: 12px;
		margin-right: 2.188rem;
		margin-left: 2.188rem;
		margin-top: -12px;
		@media screen and (max-width: 800px) {
			margin-right: 0.625rem;
			margin-left: 0.625rem;
		}
		@media screen and (max-width: 620px) {
			margin-top: -18px;
		}
	}

	.topIndex {
		z-index: 9;
		position: relative;
	}

	.titleBanner {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 64px;
		@media screen and (max-width: ${Mobile}) {
			font-size: 1.5rem;
			display: unset;
		}

		.Typewriter {
			margin-left: 7px;
			min-height: 42px;
			@media screen and (max-width: ${Mobile}) {
				margin-left: 0;
			}
		}
		.Typewriter__cursor {
			display: none;
		}
	}

	.bannerSubTitle {
		padding-left: 18%;
		padding-right: 18%;
		@media only screen and (min-width: ${Large}) {
			padding-left: 2%;
			padding-right: 2%;
		}
		@media only screen and (min-width: ${ExtraLarge}) {
			font-size: 1.5rem !important;
		}

		@media screen and (max-width: ${Mobile}) {
			padding-left: 10%;
			padding-right: 10%;
		}

		&.enLarge {
			@media screen and (max-width: 922px) {
				br {
					content: ' ';
				}
			}
		}
	}

	.textWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-left: 10%;
		padding-right: 10%;
		@media only screen and (max-width: 1300px) {
			padding-left: 0%;
			padding-right: 0%;
		}
		@media only screen and (min-width: ${Large}) {
			padding-left: 8%;
			padding-right: 8%;
		}
		@media only screen and (min-width: ${ExtraLarge}) {
			padding-left: 0%;
			padding-right: 0%;
		}
		.desktopTitle {
			@media only screen and (max-width: ${CUSTOM_TABLET}px) {
				display: none;
			}
		}

		.mobileTitle {
			display: none;
			@media only screen and (max-width: ${CUSTOM_TABLET}px) {
				display: unset;
			}
		}

		.pDefault {
			margin-top: 1rem;
		}

		.txtBackedBy {
			margin-top: 1.563rem;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				margin-left: 0.174;
			}
		}

		.btnPrimaryDefault {
			margin-top: 2rem;
			@media only screen and (max-width: ${Mobile}) {
				margin-top: 2.25rem;
			}
		}
	}

	.tabImageWrapper {
		.buttomTabWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 3rem;
			margin-bottom: 3.75rem;
			@media only screen and (max-width: ${Tablet}) {
				margin-bottom: 0;
				margin-top: 1.875rem;
			}

			ul {
				list-style: none;
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
				li {
					margin: 0;

					display: flex;
					align-items: center;

					button {
						padding: 0;
						margin: 0;
						background-color: transparent;
						border-left: none;
						border-top: none;
						border-right: none;
						border-bottom: 4px solid transparent;
						padding-left: 1.563rem;
						padding-right: 1.563rem;
						padding-bottom: 0.5rem;
						margin-top: -5px;
						@media only screen and (max-width: 480px) {
							padding-right: 0.5rem;
							padding-left: 0.5rem;
						}
						.h3Default {
							margin: 0;
							padding: 0;
							font-size: 1.15rem;
							@media only screen and (max-width: 620px) {
								font-size: 1rem;
							}
						}
						&.active {
							border-bottom: 4px solid #0664be;
						}
					}
				}
			}
		}
		.imageTabWrapper {
			.slick-slide {
				@media only screen and (max-width: ${CUSTOM_TABLET}px) {
					height: 400px;
				}
				@media only screen and (max-width: 500px) {
					height: 350px;
				}
				@media only screen and (max-width: ${Mobile}) {
					height: 285px;
				}
				div {
					height: 100%;

					div {
						height: 100%;
					}
				}
			}
			.imageItem {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				@media only screen and (max-width: ${CUSTOM_TABLET}px) {
					picture,
					img {
						width: 80%;
						display: flex;
						align-items: flex-end;
						justify-content: center;
					}
				}

				@media only screen and (max-width: ${Mobile}) {
					picture,
					img {
						width: 90%;
						display: flex;
						align-items: flex-end;
						justify-content: center;
					}
				}
			}
		}
	}

	.quarterCircle {
		background-image: url(https://seiturju.sirv.com/Chatbiz/homepage/QuarterCircle.svg);
		width: 300px;
		height: 361px;

		transform: scale(1);
		position: absolute;
		top: 82px;
		right: 0;
		background-repeat: no-repeat;
		z-index: 1;

		transform-origin: right top;
		@media only screen and (max-width: ${Tablet}) {
			transform: scale(0.6);
			top: 60px;
			opacity: 0.25;
		}
	}
`;

export default Wrapper;
