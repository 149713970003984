import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	&.pDefault {
		font-family: Montserrat, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 0.875rem;
		line-height: 150%;
		color: #4f4f4f;
		span.bolder {
			font-weight: bold;
		}
	}
`;

const Paragraph = ({ children, className }) => {
	return <Wrapper className={className || 'pDefault'}>{children}</Wrapper>;
};

Paragraph.defaultProps = {
	className: '',
};

Paragraph.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Paragraph;
