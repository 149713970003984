import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import H1 from '../../../atoms/Label/Headers/H1/clear';
import H3 from '../../../atoms/Label/Headers/H3';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import ImageRender from '../../../../helper/image';
import { Wrapper } from './style';

const GetFrom = () => {
	const intl = useIntl();
	return (
		<Wrapper>
			<div className="container container-mobile">
				<div className="textWrapper">
					<H1>{intl.formatMessage({ id: `homepage.get_from_chatbiz.title` })}</H1>
					<Paragraph>{intl.formatMessage({ id: `homepage.get_from_chatbiz.sub` })}</Paragraph>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="listWrapper">
							<ul>
								<li>
									{ImageRender(
										'https://seiturju.sirv.com/Chatbiz/v4/ico-manage-service.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-manage-service.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-manage-service.png?format=webp&q=100',
									)}
									<H3>{intl.formatMessage({ id: `homepage.get_from_chatbiz.list_1` })}</H3>
								</li>
								<li>
									{ImageRender(
										'https://seiturju.sirv.com/Chatbiz/v4/ico-green-tick.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-green-tick.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-green-tick.png?format=webp&q=100',
									)}

									<H3>{intl.formatMessage({ id: `homepage.get_from_chatbiz.list_2` })}</H3>
								</li>
								<li>
									{ImageRender(
										'https://seiturju.sirv.com/Chatbiz/v4/ico-free-consult.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-free-consult.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-free-consult.png?format=webp&q=100',
									)}

									<H3>{intl.formatMessage({ id: `homepage.get_from_chatbiz.list_3` })}</H3>
								</li>
								<li>
									{ImageRender(
										'https://seiturju.sirv.com/Chatbiz/v4/ico-24-hour.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-24-hour.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-24-hour.png?format=webp&q=100',
									)}

									<H3>{intl.formatMessage({ id: `homepage.get_from_chatbiz.list_4` })}</H3>
								</li>
								<li>
									{ImageRender(
										'https://seiturju.sirv.com/Chatbiz/v4/ico-analytics.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-analytics.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-analytics.png?format=webp&q=100',
									)}

									<H3>{intl.formatMessage({ id: `homepage.get_from_chatbiz.list_5` })}</H3>
								</li>
								<li>
									{ImageRender(
										'https://seiturju.sirv.com/Chatbiz/v4/ico-uptime-99.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-uptime-99.png?format=webp&q=100',
										'https://seiturju.sirv.com/Chatbiz/v4/ico-uptime-99.png?format=webp&q=100',
									)}

									<H3>{intl.formatMessage({ id: `homepage.get_from_chatbiz.list_6` })}</H3>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default GetFrom;
