import styled from 'styled-components';
import { Tablet, Large } from '../../../atoms/_Variable/var';

const MOBILE_BREAK_POINT = 900;

export const Wrapper = styled.div`
	background-color: #ffffff;
	@media screen and (max-width: ${Tablet}) {
		.container-mobile {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			max-width: 100%;
		}
	}

	@media screen and (min-width: ${Large}) {
		.container-mobile {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media screen and (min-width: 1500px) {
		.container-mobile {
			max-width: 1300px;
		}
	}

	.topBackground {
		background-color: #f9fbff;
		height: 120px;
	}

	.wrapper-list {
		padding-right: 8%;
		padding-left: 8%;

		margin-top: -125px;

		@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
			padding-left: 0;
			padding-right: 0;
		}

		.content {
			display: flex;
			align-items: center;
			background: linear-gradient(242.15deg, #0661bd 25.09%, #066fc2 92.28%);
			box-shadow: 0px 4px 20px rgba(11, 74, 148, 0.2);
			border-radius: 8px;
			padding: 2.5rem 3.313rem 2.5rem 3.313rem;
			position: relative;

			@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
				align-items: flex-start;
				flex-direction: column;
				padding: 2.5rem 2.5rem 2.875rem 2.5rem;
			}

			@media screen and (max-width: ${Tablet}) {
				padding: 2.063rem 1.625rem 2.938rem 1.625rem;
			}

			&__left {
				@media screen and (max-width: ${Tablet}) {
					picture,
					img {
						max-width: 86px;
					}
				}
			}

			&__right {
				margin-left: 2.5rem;
				@media screen and (max-width: ${MOBILE_BREAK_POINT}px) {
					margin-left: 0;
					margin-top: 1.5rem;
				}
				.btnPrimaryDefault {
					padding: 0.625rem 2rem !important;
				}
				.h1Default,
				.pDefault {
					color: #ffffff;
				}
				.txtContent {
					margin-top: 1rem;
					margin-bottom: 2.375rem;
				}
			}

			.quarterCircle {
				background-image: url(https://seiturju.sirv.com/Chatbiz/v4/quarter-circle2.svg);
				width: 230px;
				height: 300px;

				position: absolute;
				top: 0px;
				right: 0;
				background-repeat: no-repeat;
				z-index: 1;

				transform-origin: right top;
			}
		}
	}
`;

export default Wrapper;
