/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import H3 from '../../../../atoms/Label/Headers/H3';
import H2 from '../../../../atoms/Label/Headers/H2';
import Paragraph from '../../../../atoms/Label/Paragraph/Clear';
import ParagraphSmall from '../../../../atoms/Label/Paragraph/Small';
import { ButtonLinkHref } from '../../../../atoms/Button/Link';
import ImageRender from '../../../../../helper/image';
import { Wrapper } from './style';

const ChatbizForLeft = ({
	img,
	companyLogo,
	txtTop,
	txtTitle,
	txtContent,
	txtButton,
	onDemoClick,
	idBtn,
}) => {
	return (
		<Wrapper>
			<div className="container">
				<div className="content">
					<div className="content__left">
						<div className="txtWrapper">
							<H3 className="h3Default txtWrapper__top">{txtTop}</H3>
							<H2 className="h2Default txtWrapper__title">{txtTitle}</H2>
							{ImageRender(companyLogo, companyLogo, companyLogo, 'txtWrapper__logoClient')}
							<Paragraph className="pDefault txtWrapper__content">
								<div dangerouslySetInnerHTML={{ __html: txtContent }} />
							</Paragraph>
							<div className="btnWrapper">
								<ButtonLinkHref href={onDemoClick} target="__blank" id={idBtn}>
									<ParagraphSmall>{txtButton}</ParagraphSmall>
								</ButtonLinkHref>
							</div>
						</div>
					</div>
					<div className="content__right">
						<div className="imgWrapper">{ImageRender(img, img, img, 'contentImg')}</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

ChatbizForLeft.propTypes = {
	img: PropTypes.string.isRequired,
	companyLogo: PropTypes.string.isRequired,
	txtTop: PropTypes.string.isRequired,
	txtTitle: PropTypes.string.isRequired,
	txtContent: PropTypes.string.isRequired,
	txtButton: PropTypes.string.isRequired,
	onDemoClick: PropTypes.func.isRequired,
	idBtn: PropTypes.string.isRequired,
};

export default ChatbizForLeft;
