/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
// import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
// import animateScrollTo from 'animated-scroll-to';
import Typewriter from 'typewriter-effect';
import Slider from 'react-slick';
import H3 from '../../../atoms/Label/Headers/H3';
import MediumText from '../../../atoms/Label/MediumText';
import Paragraph from '../../../atoms/Label/Paragraph/Clear';
import SmallParagraph from '../../../atoms/Label/Paragraph/Small';
import ButtonPrimary from '../../../atoms/Button/Primary';
import ImageRender from '../../../../helper/image';
import { Wrapper } from './style';

const settingsSlick = {
	dots: false,
	infinite: true,
	autoplay: true,
	autoplaySpeed: 3000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrow: false,
};

const BannerHome = ({ onClickButton, enPrefix }) => {
	const intl = useIntl();
	const [selectedTab, setSelectedTab] = useState(1);

	const listImages = [
		'https://seiturju.sirv.com/Chatbiz/v4/banner-chatpanel.webp?format=webp&q=100',
		'https://seiturju.sirv.com/Chatbiz/v4/banner-chatbot.webp?format=webp&q=100',
		'https://seiturju.sirv.com/Chatbiz/v4/banner-analytic.webp?format=webp&q=100',
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const typeText = (
		<Typewriter
			options={{
				loop: true,
			}}
			onInit={typewriter => {
				typewriter
					.changeDelay(1.5)
					.changeDeleteSpeed(0.5)
					.typeString(intl.formatMessage({ id: `homepage.banner.title.for_list_1` }))
					.pauseFor(2000)
					.deleteChars(intl.formatMessage({ id: `homepage.banner.title.for_list_1` }).length)
					.typeString(intl.formatMessage({ id: `homepage.banner.title.for_list_2` }))
					.pauseFor(2000)
					.deleteChars(intl.formatMessage({ id: `homepage.banner.title.for_list_2` }).length)
					.typeString(intl.formatMessage({ id: `homepage.banner.title.for_list_3` }))
					.pauseFor(2000)
					.deleteChars(intl.formatMessage({ id: `homepage.banner.title.for_list_3` }).length)
					.start();
			}}
		/>
	);

	return (
		<Wrapper className="banner-home">
			<div className="container container-mobile">
				<div className="row rowBannerHome">
					<div className="col-12">
						<div className="textWrapper">
							<div className="desktopTitle">
								<MediumText className="topIndex mediumTextDefault titleBanner">
									{intl.formatMessage({ id: `homepage.banner.title.title_top` })}{' '}
									{intl.formatMessage({ id: `homepage.banner.title.title_bottom` })}
								</MediumText>
								<MediumText className="topIndex mediumTextDefault titleBanner">{typeText}</MediumText>
							</div>

							<div className="mobileTitle">
								<MediumText className="topIndex mediumTextDefault titleBanner">
									{intl.formatMessage({ id: `homepage.banner.title.title_top` })}{' '}
									{intl.formatMessage({ id: `homepage.banner.title.title_bottom` })}
								</MediumText>

								<MediumText className="topIndex mediumTextDefault titleBanner">{typeText}</MediumText>
							</div>

							<Paragraph
								className={
									enPrefix ? 'topIndex pDefault bannerSubTitle enLarge' : 'topIndex pDefault bannerSubTitle'
								}
							>
								<div
									dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `homepage.banner.sub` }) }}
								/>
							</Paragraph>
							<ButtonPrimary
								className="btnPrimaryDefault btnCtaOnly"
								id="CTA-hp-head"
								onClick={onClickButton}
							>
								{intl.formatMessage({ id: `homepage.banner.button` })}
							</ButtonPrimary>
							<SmallParagraph className="pDefault txtBackedBy">
								Backed by
								{ImageRender(
									'https://seiturju.sirv.com/Chatbiz/v4/y-combinator.png?format=webp&q=100',
									'https://seiturju.sirv.com/Chatbiz/v4/y-combinator.png?format=webp&q=100',
									'https://seiturju.sirv.com/Chatbiz/v4/y-combinator.png?format=webp&q=100',
								)}
							</SmallParagraph>
						</div>
						<div className="tabImageWrapper">
							<div className="buttomTabWrapper">
								<ul>
									<li>
										<button
											type="button"
											className={selectedTab === 0 ? 'active' : ''}
											onClick={() => {
												setSelectedTab(0);
											}}
										>
											<H3>{intl.formatMessage({ id: `homepage.banner.tab.list_1` })}</H3>
										</button>
										<div className="hrVertical" />
									</li>
									<li>
										<button
											type="button"
											className={selectedTab === 1 ? 'active' : ''}
											onClick={() => {
												setSelectedTab(1);
											}}
										>
											<H3>{intl.formatMessage({ id: `homepage.banner.tab.list_2` })}</H3>
										</button>
										<div className="hrVertical" />
									</li>
									<li>
										<button
											type="button"
											className={selectedTab === 2 ? 'active' : ''}
											onClick={() => {
												setSelectedTab(2);
											}}
										>
											<H3>{intl.formatMessage({ id: `homepage.banner.tab.list_3` })}</H3>
										</button>
									</li>
								</ul>
							</div>
							<div className="imageTabWrapper">
								<Slider
									{...settingsSlick}
									ref={slider => (slider ? slider.slickGoTo(selectedTab || 0) : '')}
									beforeChange={(current, next) => {
										setSelectedTab(Number(next || 0));
									}}
								>
									{listImages.map(v => (
										<div>
											<div className="imageItem">{ImageRender(v, v, v)}</div>
										</div>
									))}
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="quarterCircle" />
		</Wrapper>
	);
};

BannerHome.propTypes = {
	onClickButton: PropTypes.func.isRequired,
	enPrefix: PropTypes.string.isRequired,
};

export default BannerHome;
