import styled from 'styled-components';
import { Tablet, Mobile, Large } from '../../../atoms/_Variable/var';

export const Wrapper = styled.section`
	background-color: #f9fbff;
	padding-top: 7.438rem;
	padding-bottom: 10rem;
	@media screen and (max-width: ${Tablet}) {
		.container-mobile {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			max-width: 100%;
		}
	}
	.list-wrapper {
		background: #ffffff;
		box-shadow: 0px 4px 10px rgba(11, 74, 148, 0.1);
		border-radius: 8px;
		margin: 25px;
		padding: 2.875rem 2.125rem 2.5rem 2.125rem;

		height: 450px;
		@media screen and (max-width: ${Tablet}) {
			margin: 10px;
		}
		@media screen and (max-width: 580px) {
			height: 480px;
		}
		@media screen and (max-width: ${Mobile}) {
			padding: 1.938rem 1.188rem 2.313rem 1.188rem;
		}

		@media screen and (min-width: ${Large}) {
			height: 500px;
			margin: 10px;
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: space-between;
			img,
			picture {
				height: 29px;
			}

			.type {
				background: #f9fbff;
				border-radius: 8px;
				padding: 0.625rem 2rem;
				.pDefault {
					color: #009ddb;
					font-style: normal;
					font-weight: bold;
				}
			}
		}
		.textimony-desc {
			margin-top: 1.75rem;
			height: 200px;
			@media screen and (max-width: 1300px) {
				height: 220px;
			}
			@media screen and (max-width: 1100px) {
				height: 150px;
			}
			@media screen and (max-width: ${Tablet}) {
				height: 220px;
			}
			@media screen and (max-width: 450px) {
				height: 250px;
			}
			@media screen and (min-width: ${Large}) {
				height: 250px;
			}
		}
		.by-wrapper {
			margin-top: 1.75rem;
			display: flex;
			&__left {
				margin-right: 1.125rem;
				.image-wrapper {
					width: 57px;
					height: 57px;
					border-radius: 50%;
					overflow: hidden;
					position: relative;
				}
				img,
				picture {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			&__right {
				.job-title {
					font-weight: bold;
					height: 40px;
					@media screen and (max-width: 550px) {
						height: 50px;
					}
				}
			}
		}
	}

	.bottomTestimony {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 3.313rem;
		@media screen and (max-width: ${Tablet}) {
			padding-left: 15px;
			padding-right: 15px;
			justify-content: center;
		}
		.listDots {
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				display: flex;

				li {
					width: 14px;
					height: 14px;
					background: #bbdde6;
					opacity: 0.4;
					border-radius: 50px;
					margin-right: 1rem;
					cursor: pointer;
					&.active {
						background: #ff8f1c;
						opacity: 1;
					}
				}
			}
		}
		.btnWrapper {
			@media screen and (max-width: ${Tablet}) {
				display: none;
			}
			button {
				border: none;
				background-color: transparent;
				max-width: 40px;
				&:focus {
					outline: none;
				}
				img {
					width: 100%;
				}
				&:nth-child(2) {
					margin-left: 1rem;
					img {
						transform: rotate(180deg);
					}
				}
			}
		}
	}
`;

export default Wrapper;
