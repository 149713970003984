import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Mobile, MiniMobile } from '../../../_Variable/var';

const Wrapper = styled.h3`
	&.h3Default {
		font-family: Montserrat, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 140%;
		color: #4f4f4f;
		@media only screen and (max-width: ${Mobile}) {
			font-size: 1.5rem;
		}
		@media only screen and (max-width: ${MiniMobile}) {
			font-size: 1.4rem;
		}
	}
`;

const H3 = ({ children, className }) => {
	return <Wrapper className={className || 'h3Default'}>{children}</Wrapper>;
};

H3.defaultProps = {
	className: '',
};

H3.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default H3;
