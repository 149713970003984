import styled from 'styled-components';
import { Tablet, Mobile } from '../../../atoms/_Variable/var';

export const CUSTOM_TABLET = 903;
export const CUSTOM_MOBILE = 600;

export const Wrapper = styled.section`
	background-color: #f9fbff;
	padding-top: 5rem;
	padding-bottom: 7.5rem;

	@media screen and (max-width: ${Tablet}) {
		.container-mobile {
			padding-left: 15px;
			padding-right: 15px;
			margin-left: 0;
			max-width: 100%;
		}
	}

	@media screen and (max-width: ${CUSTOM_MOBILE}px) {
		padding-bottom: 5.25rem;
	}

	.textWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-left: 15%;
		padding-right: 15%;
		@media screen and (max-width: ${Tablet}) {
			padding-left: 5%;
			padding-right: 5%;
		}
		.pDefault {
			max-width: 720px;
			margin-top: 1.5rem;
		}
	}

	.listWrapper {
		ul {
			list-style: none;
			display: flex;
			justify-content: space-evenly;
			flex-wrap: wrap;
			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 29%;
				margin-top: 3.75rem;
				@media screen and (max-width: ${CUSTOM_TABLET}px) {
					width: 50%;
					margin-top: 2rem;
				}
				@media screen and (max-width: ${CUSTOM_MOBILE}px) {
					width: 100%;
					margin-top: 1.5rem;
				}
				picture,
				img {
					max-width: 72px;
					@media screen and (max-width: ${Mobile}) {
						max-width: 64px;
					}
				}
				.h3Default {
					margin-left: 1.25rem;
					font-size: 1rem;
					max-width: 280px;
					@media screen and (max-width: ${CUSTOM_MOBILE}px) {
						max-width: 100%;
					}
				}
			}
		}
	}
`;

export default Wrapper;
