import styled from 'styled-components';
import { Tablet, Mobile } from '../../../../atoms/_Variable/var';

export const Wrapper = styled.section`
	background-color: #ffffff;
	padding-top: 6.25rem;
	padding-bottom: 5.25rem;
	@media screen and (max-width: ${Mobile}) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	.content {
		display: flex;
		@media screen and (max-width: ${Tablet}) {
			flex-direction: column;
		}
		&__left {
			width: 40%;
			@media screen and (max-width: ${Tablet}) {
				width: 100%;
				display: flex;
				align-items: center;
			}
		}

		&__right {
			width: 60%;
			@media screen and (max-width: ${Tablet}) {
				width: 100%;
			}
		}
	}
	.imgWrapper {
		@media screen and (max-width: ${Tablet}) {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		img,
		picture,
		.contentImg {
			width: 100%;
			-webkit-backface-visibility: hidden;
			-ms-transform: translateZ(0);
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			image-rendering: -moz-crisp-edges;
			image-rendering: -o-crisp-edges;
			image-rendering: -webkit-optimize-contrast;
			image-rendering: crisp-edges;
			-ms-interpolation-mode: nearest-neighbor;
			@media screen and (max-width: ${Tablet}) {
				width: 80%;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			@media screen and (max-width: ${Mobile}) {
				width: 98%;
			}
		}
	}
	.txtWrapper {
		display: flex;
		flex-direction: column;
		margin-left: 3.75rem;
		max-width: 610px;
		@media screen and (max-width: ${Tablet}) {
			margin-left: 0;
			margin-top: 2.5rem;
		}
		&__top {
			color: #ff8f1c;
			font-size: 1rem;
			font-style: normal;
			font-weight: bold;
		}
		&__title {
			margin-top: 2rem;
			margin-bottom: 2rem;
			@media screen and (max-width: ${Tablet}) {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}
		&__logoClient {
			max-height: 30px;
			-webkit-backface-visibility: hidden;
			-ms-transform: translateZ(0);
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			image-rendering: -moz-crisp-edges;
			image-rendering: -o-crisp-edges;
			image-rendering: -webkit-optimize-contrast;
			image-rendering: crisp-edges;
			-ms-interpolation-mode: nearest-neighbor;
		}
		&__content {
			margin-top: 0.5rem;
			margin-bottom: 2rem;

			span {
				font-weight: bold;
			}
		}
	}

	.btnWrapper {
		display: flex;
		.btnLinkDefault {
			.pDefault {
				font-style: normal;
				font-weight: bold;
			}
		}
	}
`;

export default Wrapper;
